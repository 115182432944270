<template>
  <div>
    <div class="row">
      <div class="col-3">
        <label for="artista">Pesquisar música</label>

        <SongsSearchInput
          @song-selected="songSeleceted"
          :artist-id="album.id_artista"
        />
      </div>

      <div class="col-4">
        <label for="artista">CD</label>

        <div class="d-flex align-items-center">
          <div>
            <select
              v-model="currentCD"
              class="form-control d-inline-block"
            >
              <option
                v-for="cd in cdCount"
                :key="cd"
              >{{ cd }}</option>
            </select>
          </div>

          <div class="ml-2">
            <button
              type="button"
              class="btn btn-secondary"
              @click="addNewCD"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <hr >

    <AlbumCD
      v-for="(cdSongs, cd) in songs"
      :key="cd"
      :cd="cd"
      :songs="cdSongs"
      @song-removed="songRemoved"
    />
  </div>
</template>

<script>
  import AlbumCD from '@/components/albums/AlbumCD'
  import SongsSearchInput from '@/components/songs/SongsSearchInput'

  export default {
    components: {
      AlbumCD,
      SongsSearchInput,
    },

    props: ['songs', 'album'],

    data: () => ({
      currentCD: 1
    }),

    computed: {
      cdCount () {
        return Object.keys(this.songs).map(i => i)
      },
    },

    methods: {
      addNewCD () {
        const newCDIndex = Object.keys(this.songs).length + 1

        this.currentCD = newCDIndex

        this.$set(this.songs, newCDIndex, [])
      },

      songSeleceted (song) {
        const { id_musica, titulo } = song

        const songs = this.songs[this.currentCD]

        const track = songs.length + 1

        songs.push({
          musica_nome: titulo,
          cd: this.currentCD,
          existe: 1,
          track,
          id_musica
        })
      },

      songRemoved (albumSong) {
        const cdCount = Object.keys(this.songs).length
        const cdSongsCount = Object.values(this.songs[albumSong.cd]).length

        if (!cdSongsCount && cdCount > 1) {
          if (albumSong.cd == this.currentCD) {
            this.currentCD = 1
          }

          this.$delete(this.songs, albumSong.cd)
        }
      }
    }
  }
</script>