<template>
  <div>
    <div class="title mb-3">CD: {{ cd }}</div>

    <SortableList
      :items="songs"
      item-key="id"
      item-sorting-key="track"
      :max-positions="songs.length"
      @item-removed="songRemoved"
    >
      <template v-slot:default="{ item }">
        <b :class="{faded: !item.existe}">
          {{ item.musica_nome }} <span v-if="!item.existe">(não registrada)</span>
        </b>
      </template>
    </SortableList>
  </div>
</template>

<script>
  import {
    deleteAlbumSong
  } from '@/api/albums'
  import SortableList from '@/components/utils/sorting/SortableList'

  export default {
    components: {
      SortableList,
    },

    props: {
      songs: { type: Array, default: () => ([]) },
      cd: { type: String, default: '' }
    },

    methods: {
      async songRemoved (albumSong) {
        if (albumSong.id) {
          await deleteAlbumSong(albumSong.id)
        }
        this.$emit('song-removed', albumSong)
      }
    }
  }
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";

    .title {
      display: block;
      padding: 20px;
      font-weight: 700;
      font-size: 22px;
      background: $pageTitleBgColor;
      border-bottom: 2px solid darken($pageTitleBgColor, 4%);
    }
</style>