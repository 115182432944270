<template>
  <div class="page-with-title">
    <page-title>
      <div class="d-flex align-items-center">
        <div>
          <h1 v-if="!isEditing">Novo álbum</h1>

          <div v-else>
            <h1>Editando álbum: {{ album.id_album }}</h1>
            <a
              :href="album.url"
              target="_blank"
            >{{ album.url }}</a>
          </div>
        </div>

        <div class="ml-4" v-if="isEditing">
          <IconMenuDropdown icon="fa fa-cog">
            <ItemDropdown
              @click="destroyAlbum"
            >excluir álbum</ItemDropdown>
          </IconMenuDropdown>
        </div>
      </div>
    </page-title>

    <form
    method="post"
    class="p-4"
    @submit.prevent="submit"
    >
    <div class="row">
      <div class="col-2">
        <div class="embed-responsive embed-responsive-1by1">
          <img
            ref="avatar"
            :src="album.avatar"
            class="embed-responsive-item"
          >

          <UploadButton
            :fullmode="true"
            @change="$e => uploadImage($e)"
          />
        </div>        
      </div>

      <div class="col-10">
        <div class="row">
          <div class="col-3">
            <label>Titulo</label>
            <input
            v-model="album.titulo"
            type="text"
            class="form-control"
            required
            />
          </div>

          <div class="col-md-3">
            <label>Artista</label>
            <AutoComplete
            :data="album.artista"
            @selected="artistSelected"
            @changed="artistChanged"
            />
          </div>

          <div class="col-md-2">
            <label>Ano</label>
            <input
              v-model="album.ano"
              type="number"
              class="form-control"
            >
          </div>

          <div class="col-3">
            <label>Gravadora</label>
            <input
            v-model="album.gravadora"
            type="text"
            class="form-control"
            />
          </div>
        </div>
      </div>
    </div>

    <hr>

    <AlbumSongs
      :songs="songs"
      :album="album"
    />

    <hr>

    <div>
      <button
        :disabled="!isSubmitEnabled"
        class="btn btn-2 btn-bigger mr-3"
      >
        <b><i class="fa fa-check" /> Salvar</b>
      </button>
    </div>
  </form>
</div>
</template>

<script>

  import Vue from 'vue'
  import {
    getAlbumById,
    updateAlbum,
    storeAlbum,
    updateAlbumAvatar,
    deleteAlbum,
    getAlbumSongs,
    updateAlbumSongs,
  } from '@/api/albums'
  import PageTitle from '@/components/titles/PageTitle'
  import AutoComplete from '@/components/utils/AutoComplete'
  import UploadButton from '@/components/utils/UploadButton'
  import albumValidation from '@/validations/albumValidation'
  import { validationMixin } from 'vuelidate'
  import IconMenuDropdown from '@/components/utils/dropdown/IconMenuDropdown'
  import ItemDropdown from '@/components/utils/dropdown/ItemDropdown'
  import AlbumSongs from '@/components/albums/AlbumSongs'

  export default {
    components: {
      PageTitle,
      AutoComplete,
      UploadButton,
      IconMenuDropdown,
      ItemDropdown,
      AlbumSongs,
    },

    props: {
      artist: { type: Object, default: () => ({}) }
    },

    mixins: [validationMixin],

    validations: { album: albumValidation },

    data: () => ({
      album: {},
      songs: []
    }),

    watch: {
      $router: {
        handler: function () {
          this.init()
        },
        immediate: true
      }
    },

    computed: {
      id () {
        return this.$route.params.id
      },

      isEditing() {
        return this.id !== 'novo'
      },

      isSubmitEnabled () {
        return !this.$v.$invalid  && this.isAvatarSet
      },

      isAvatarSet () {
        return !!this.album.avatar_file || !!this.album.url_capa
      }
    },

    methods: {
      init () {
        if (this.isEditing) {
          this.fetchAlbum()
            .catch(e => this.$router.replace({ name: 'Dashboard' }))

          this.fetchAlbumSongs()
        } else {
          this.songs = {"1": []}
        }

        this.handlePreDefinedArtist()
      },

      async submit () {
        if (this.isEditing) {
          await this.update()
        } else {
          const album = await this.store()

          await this.$router.replace({
            name: 'album',
            params: { id: album.id_album }
          })

          this.$router.go()
        }
      },

      async store () {
        let { avatar_file, ...album } = this.album

        album = await storeAlbum(album)

        await updateAlbumAvatar(album.id_album, avatar_file)

        await updateAlbumSongs(album.id_album, this.songs)

        return album
      },

      async update () {
        const { avatar_file, id_album, ...album } = this.album

        await updateAlbum(id_album, album)

        if (avatar_file) {
          await updateAlbumAvatar(id_album, avatar_file)
        }

        await updateAlbumSongs(this.id, this.songs)
      },

      async destroyAlbum () {
        let message = 'Tem CERTEZA que deseja excluir PEMANENTEMENTE'
        message = `${message} esse álbum?`

        if (window.confirm(message)) {
          await deleteAlbum(this.id)

          this.$router.replace({
            name: 'artistShow',
            params: { id: this.album.cod_artista }
          })
        }
      },

      async fetchAlbum () {
        const album = await getAlbumById(this.id)
        this.album = Object.assign({}, this.album, album)
      },

      async fetchAlbumSongs () {
        this.songs = await getAlbumSongs(this.id)
      },

      artistChanged () {
        Vue.set(this.album, 'id_artista', null)
      },

      artistSelected (artist) {
        Vue.set(this.album, 'artista', artist.artista)
        Vue.set(this.album, 'id_artista', artist.id_artista)
      },

      uploadImage($e) {
        const file = $e.target.files[0]
        const reader = new FileReader()

        reader.onloadend = () => {
          this.album.avatar = reader.result
          Vue.set(this.album, 'avatar_file', file)
        }

        if (file) {
          reader.readAsDataURL(file)
        }
      },

      handlePreDefinedArtist () {
        if (this.artist) {
          this.artistSelected(this.artist)
        }
      },
    }
  }
</script>
